export function formateBoxofficeDate(inputDate) {
  if (inputDate == null) {
    return null;
  }

  let dateParts = new Date(inputDate).toString().split(" ");
  let formattedDate = `${dateParts[2]} ${dateParts[1]} ${dateParts[3]}`;
  return formattedDate;

}

let months = ['months', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
export function getMonth(number) {
  return months[number];
}

export function numberWithCommas(x) {
  if (x == null) {
    return null;
  }
  // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}