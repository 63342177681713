import './App.css';
import { useState, useEffect } from 'react';
import TitleTable from './components/title-table';
import "ag-grid-community/styles/ag-grid.css";
import logo from './img/universalLogo.png';
import { FRONT_END_CONST } from './constants/constants';
const APP_ENV = FRONT_END_CONST.MIDDLEWARE_NODE_ENV;


function App() {
  const [movies, setMovies] = useState([]);
  const [fetchTime, setFetchTime] = useState("");
  const [sessionTimeout] = useState(1800); // in seconds
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [showTimeoutDialog, setShowTimeoutDialog] = useState(false);


  const LogoutButton = () => {
    const boxOfficeMiddleWareUrl = (APP_ENV === 'PROD') ? (FRONT_END_CONST.MIDDLEWARE_NODE_URL_PROD) : ((APP_ENV === 'UAT') ? (FRONT_END_CONST.MIDDLEWARE_NODE_URL_UAT) : (FRONT_END_CONST.MIDDLEWARE_NODE_URL_LOCAL));
    const logoutUrl = boxOfficeMiddleWareUrl + 'logout';
    console.log(logoutUrl);
    
    fetch(
      logoutUrl,
      {
        method: 'GET' }
    ).then(response => console.log(response))
    .catch(error => {
    console.error(error);
  });
    }

  useEffect(() => {
    let timeoutId;

    const handleSessionTimeout = () => {
      setIsLoggedIn(false);
      setShowTimeoutDialog(true);
      console.log('Inside handle session for dialog');
      LogoutButton();
    };

    const resetSessionTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleSessionTimeout, sessionTimeout * 1000);
    };

    resetSessionTimeout(); 

    const handleUserActivity = () => {
      resetSessionTimeout();
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      clearTimeout(timeoutId);
    };
  }, [sessionTimeout]);

  const handleCloseDialog = () => {
    setShowTimeoutDialog(false);
    window.location.reload();
    //LogoutButton();
    
  };


  const getData = () => {
    //URL for node middle ware API
    const boxOfficeMiddleWareUrl = (APP_ENV === 'PROD') ? (FRONT_END_CONST.MIDDLEWARE_NODE_URL_PROD) : ((APP_ENV === 'UAT') ? (FRONT_END_CONST.MIDDLEWARE_NODE_URL_UAT) : (FRONT_END_CONST.MIDDLEWARE_NODE_URL_LOCAL));
    //API call to node middle ware server to get UPI data
    fetch(
      boxOfficeMiddleWareUrl,
      {
        method: 'GET'
      }
    ).then(response1 =>
      response1.json().then(data => ({
        data: data,
        status: response1.status
      })
      ).then(resp => {
        // set the UPI data to react state  'movies'
        setMovies(resp.data.boxofficeData);
        //set the feth time to react state  'fetchTime'
        if (resp.data && resp.data.fetchTime) {
          setFetchTime(resp.data.fetchTime.split("GMT")[0]);
        }
        else {
          setFetchTime("defaultTime");
        }
      }));

  }

  

  useEffect(() => {
    getData();
  }, []);

 

  return (
    <>
     {isLoggedIn &&
      <div className='wrapper'>
        <header>
          <div className='logoContainer'>
            <img width={200} height={100} src={logo} alt='universal logo' />
          </div>
          <div className='headingBox'>
            <h2>{FRONT_END_CONST.MAIN_HEADING1}</h2>
            <h2>{FRONT_END_CONST.MAIN_HEADING2}</h2>
            <h3>{FRONT_END_CONST.SUB_HEADING} {fetchTime}</h3>
            <h4>{FRONT_END_CONST.QUOTES}</h4>
          </div>
        </header>
        <div className='mainContainer'>
          <TitleTable titles={movies}></TitleTable>
        </div>
      </div> }
      {showTimeoutDialog && (
        <div className="dialog-overlay">
        <div className="dialog-box">
        <p>Session timed out. Please log in.</p>
        <button className="ok-button" onClick={handleCloseDialog}>OK</button>
        </div>
        </div>
        )}
       
      
    </>
  );
}

export default App;
