import { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import PopupComponent from './popup-component';
import { formateBoxofficeDate, numberWithCommas } from '../libs/formatters';
import { FRONT_END_CONST } from '../constants/constants';

export default function TitleTable(props) {
  const [show, setShow] = useState(false);

  const [territory, setTerritory] = useState([]);
  const [titleName, setTitleName] = useState('');


  const handleShow = (e) => {
    setTerritory(e.data.Territory);
    setTitleName(e.data.titlename);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const filmTitles = props.titles;
  if (Array.isArray(filmTitles)) {
    filmTitles.forEach((el) => {
      el.totalWkndGross = el.Territory.reduce((acc, obj) => {
        // return acc + obj.gross;
        //MAG-5176 - UPI BO - Include the Preview BO with the Weekend BO
        return acc + obj.wkndGross + obj.grossprv;
      }, 0);
    });
    filmTitles.sort((a, b) => {
      let fa = a.titlename.toLowerCase(),
        fb = b.titlename.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });

  }


  const [columnDefs] = useState([
    {
      field: 'titlename',
      headerName: FRONT_END_CONST.TABLE_HEADERS.MAIN_TABLE.LABELS.TITLE_NAME,
      sortable: true,
      filter: true,
    },
    {
      field: 'firstIntlReleaseDate',
      headerName: FRONT_END_CONST.TABLE_HEADERS.MAIN_TABLE.LABELS.RELEASE_DATE,
      sortable: true,
      cellRenderer: (data) => {
        return data.value ? formateBoxofficeDate(data.value) : '';
      }
    },
    {
      field: 'totalWkndGross',
      headerName: FRONT_END_CONST.TABLE_HEADERS.MAIN_TABLE.LABELS.TOTAL_WKND_GROSS,
      sortable: true,
      cellRenderer: (data) => {
        return data.value ? numberWithCommas(data.value) : '';
      }
    },
    {
      field: 'UPIInternationalGross',
      headerName: FRONT_END_CONST.TABLE_HEADERS.MAIN_TABLE.LABELS.UPI_GROSS,
      sortable: true,
      cellRenderer: (data) => {
        return data.value ? numberWithCommas(data.value) : '';
      }
    }
  ]);

  const onGridReady = e => {
    e.api.sizeColumnsToFit();
  }

  return (
    <>
      <PopupComponent territoryDetails={territory} titleName={titleName} showModal={show} handleClose={handleClose}></PopupComponent>
      <div className="ag-theme-alpine" id='totalTable' style={{
        height: '80vh',
        overflow: 'auto'
      }}>
        <AgGridReact
          rowData={filmTitles}
          columnDefs={columnDefs}
          onRowClicked={(e) => { handleShow(e) }}
          onGridReady={onGridReady}
        >
        </AgGridReact>
      </div>
    </>
  );
}
