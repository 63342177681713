import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { CSVLink } from "react-csv";
import { formateBoxofficeDate, numberWithCommas } from '../libs/formatters';
import { FRONT_END_CONST } from '../constants/constants';

function PopupComponent(props) {
    const showModal = props.showModal;
    const territoryDetails = props.territoryDetails;
    const territoryName = props.titleName;

    const totalLocation = territoryDetails.reduce((acc, obj) => {
        return acc + obj.wkndLocs;
    }, 0);

    const maxWeek = Math.max(...territoryDetails.map(obj => obj.weeks));

    const totalGrossPrv = territoryDetails.reduce((acc, obj) => {
        return acc + obj.grossprv;
    }, 0);
    const totalLatestBoWkndUSD = territoryDetails.reduce((acc, obj) => {
        return acc + obj.wkndGross;
    }, 0);
    const totalCumulativeBoUsd = territoryDetails.reduce((acc, obj) => {
        return acc + obj.gross;
    }, 0);


    const totalDetails = [
        {
            maxWeek: maxWeek,
            totalLocation: totalLocation,
            totalCumulativeBoUsd: numberWithCommas(totalCumulativeBoUsd),
            totalLatestBoWkndUSD: numberWithCommas(totalLatestBoWkndUSD),
            totalGrossPrv: numberWithCommas(totalGrossPrv)
        }];

    const handleClose = () => props.handleClose();


    const [columnDefs] = useState([
        {
            field: 'territoryName',
            headerName: FRONT_END_CONST.TABLE_HEADERS.POPUP_TABLE.LABELS.TERRITORY_NAME,
            sortable: true,
            filter: true,
            minWidth: 220
        },
        {
            field: 'release',
            headerName: FRONT_END_CONST.TABLE_HEADERS.POPUP_TABLE.LABELS.RELEASE_DATE,
            sortable: true,
            cellRenderer: (data) => {
                return data.value ? formateBoxofficeDate(data.value) : '';
            }
        },
        {
            field: 'reported',
            headerName: FRONT_END_CONST.TABLE_HEADERS.POPUP_TABLE.LABELS.LATEST_BO_DATE,
            sortable: true,
            cellRenderer: (data) => {
                return data.value ? formateBoxofficeDate(data.value) : '';
            }
        },
        {
            field: 'weeks',
            headerName: FRONT_END_CONST.TABLE_HEADERS.POPUP_TABLE.LABELS.NUMBER_OF_WEEKS,
            sortable: true,
            maxWidth: 150
        },
        {
            field: 'wkndLocs',
            headerName: FRONT_END_CONST.TABLE_HEADERS.POPUP_TABLE.LABELS.NUMBER_OF_LOCATIONS,
            sortable: true,
            maxWidth: 150
        },
        {
            field: 'grossprv',
            headerName: FRONT_END_CONST.TABLE_HEADERS.POPUP_TABLE.LABELS.PREVIEWS,
            sortable: true,
            cellRenderer: (data) => {
                return data.value ? numberWithCommas(data.value) : '';
            }
        },
        {
            field: 'grossprvLC',
            headerName: FRONT_END_CONST.TABLE_HEADERS.POPUP_TABLE.LABELS.PREVIEWS_LC,
            sortable: true,
            cellRenderer: (data) => {
                return data.value ? numberWithCommas(data.value) : '';
            }
        },
        {
            field: 'wkndGross',
            headerName: FRONT_END_CONST.TABLE_HEADERS.POPUP_TABLE.LABELS.LATEST_WKND_BO,
            sortable: true,
            cellRenderer: (data) => {
                return data.value ? numberWithCommas(data.value) : '';
            }
        },
        {
            field: 'wkndGrossLC',
            headerName: FRONT_END_CONST.TABLE_HEADERS.POPUP_TABLE.LABELS.LATEST_WKND_BO_LC,
            sortable: true,
            cellRenderer: (data) => {
                return data.value ? numberWithCommas(data.value) : '';
            }

        },
        {
            field: 'gross',
            headerName: FRONT_END_CONST.TABLE_HEADERS.POPUP_TABLE.LABELS.CUMULATIVE_BO,
            sortable: true,
            cellRenderer: (data) => {
                return data.value ? numberWithCommas(data.value) : '';
            }
        },
        {
            field: 'grossLC',
            headerName: FRONT_END_CONST.TABLE_HEADERS.POPUP_TABLE.LABELS.CUMULATIVE_BO_LC,
            sortable: true,
            cellRenderer: (data) => {
                return data.value ? numberWithCommas(data.value) : '';
            }
        }
    ]);

    const [totalDefs] = useState([
        { field: 'maxWeek', headerName: FRONT_END_CONST.TABLE_HEADERS.TOTAL_TABLE.LABELS.MAX_WEEKS },
        { field: 'totalLocation', headerName: FRONT_END_CONST.TABLE_HEADERS.TOTAL_TABLE.LABELS.TOTAL_LOCATIONS },
        { field: 'totalGrossPrv', headerName: FRONT_END_CONST.TABLE_HEADERS.TOTAL_TABLE.LABELS.TOTAL_GROSS_PRV },
        { field: 'totalLatestBoWkndUSD', headerName: FRONT_END_CONST.TABLE_HEADERS.TOTAL_TABLE.LABELS.TOTAL_LATEST_BO_WKND },
        { field: 'totalCumulativeBoUsd', headerName: FRONT_END_CONST.TABLE_HEADERS.TOTAL_TABLE.LABELS.TOTAL_CUM_BO }
    ]);

    const csvName = `${territoryName}.csv`
    const csvHeaders = [
        { label: 'Territory Name', key: 'territoryName', resizable: true },
        { label: 'Release Date', key: 'release', resizable: true },
        { label: 'Number of Weeks', key: 'weeks' },
        { label: 'Latest Bo Date', key: 'reported' },
        { label: 'Number  of Locations', key: 'wkndLocs' },
        { label: 'Previews($)', key: 'grossprv' },
        { label: 'Previews(LC)', key: 'grossprvLC' },
        { label: 'Latest Weekend BO($)', key: 'wkndGross' },
        { label: 'Latest Weekend BO(LC)', key: 'wkndGrossLC' },
        { label: 'Cumulative BO($)', key: 'gross' },
        { label: 'Cumulative BO (LC)', key: 'grossLC' },
    ];

    const onGridReady = e => {
        e.api.sizeColumnsToFit();

    }
    const onTotalGridReady = e => {
        e.api.sizeColumnsToFit();

    }


    return (
        <>

            <Modal show={showModal} onHide={handleClose} size="xl" scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>{territoryName}</Modal.Title>
                    <div className='csvLink'>

                        <CSVLink
                            data={territoryDetails}
                            filename={csvName}
                            target="_blank"
                            headers={csvHeaders}
                        >Download To Excel</CSVLink>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="ag-theme-alpine" style={{ height: 460 }}>

                        <AgGridReact
                            rowData={territoryDetails}
                            columnDefs={columnDefs}
                            onGridReady={onGridReady}
                        >
                        </AgGridReact>

                    </div>
                    <div className="ag-theme-alpine" id='totalTable' style={{ height: 105, width: '90%', position: 'relative', left: '10%', marginTop: 5 }}>

                        <AgGridReact
                            rowData={totalDetails}
                            columnDefs={totalDefs}
                            onGridReady={onTotalGridReady}
                        >
                        </AgGridReact>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default PopupComponent;